<template>
    <div class="card">
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
            <div class="m-2">
                <b-row class="mb-1">
                    <b-col cols="12" md="6"            
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h4 class="table_title" style="padding:0px;">Document List</h4>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <v-select
                            v-model="perPage"
                            style="width: 90px"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>
                </b-row>
            </div>
            <b-table
                striped
                hover
                responsive
                show-empty
                v-model="applicationTable"
                empty-text="No matching records found"
                :per-page="perPage"
                :current-page="currentPage"
                :items="documentList"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="position-relative promotionalNotification"
            >
                <template #cell(id)="data">
                    {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                </template>
                <template #cell(title)="data">
                    <div>
                        {{data.item.title}}
                    </div>
                </template>

                <template #cell(discription)="data">
                    {{data.item.discription}}
                </template>

                <template #cell(createdAt)="data">
                        {{dateFormat(data.item.createdAt)}}
                </template>
                <template #cell(attachment)="data">
                        {{displayFunction(data.item.attachmentName)}}
                        <a :href="data.item.attachment" target="blank" v-if="data.item.attachment !== 'N/A'">
                          <feather-icon
                            icon="EyeIcon"
                            size="16"
                        />
                        </a>
                </template>
                <template #cell(action)="data">
                    <div class="d-flex align-items-center">
                        <img src='@/assets/images/erflog/Download.png' class="action_icon" @click="dowmloadDoc(data.item)"/>
                    </div>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <div class="row">
                    <b-col md="6" class="d-flex align-items-center">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ perPage }} of {{ documentList.length }} entries</span>
                        <!-- <span class="text-muted mt-1">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span> -->
                    </b-col>
                    <b-col  md="6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="documentList.length"
                            :per-page="perPage"
                            align="right"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0 custom_pagination"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BSpinner,
  BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import { push } from 'postcss-rtl/lib/affected-props';
export default {
   components: {
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BSpinner,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        vSelect,
        Ripple
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50],
            applicationTable:[],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            isSpinner:false,
            filterOn: [],
            fields: [
                {
                    key: 'title', label: 'Title',sortable: true
                },
                {
                    key: 'discription', label: 'Discription',sortable: true
                },
                {
                    key: 'createdAt', label: 'Create Time',sortable: true
                }, 
                {
                    key: 'attachment', label: 'Attachment',sortable: true
                }, 
                // {
                //     key: 'isdocenabled', label: 'Status',sortable: true
                // }, 
                {
                    key: 'action',label: 'Action',sortable: false
                }
            ],
            documentList:[],
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        displayFunction(name) {
            let withExte = name.split('.')[0];
            let fName = withExte.split('_')[0];
            if(withExte.split('_').length > 2){
                return withExte.split('_').slice(0,withExte.split('_').length-1).join('_') +'.'+ name.split('.')[1];
            } else {
                return fName +'.'+ name.split('.')[1];
            }
        },
        dateFormat(data) {
            return moment(new Date(data.seconds * 1000)).format("DD-MM-YYYY HH:mm")
        },
        dowmloadDoc(fileObject){
            var self = this;
            self.$axios({
                url: fileObject.attachment,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
    
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileObject.attachmentName);
                document.body.appendChild(fileLink);
    
                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
            })
        }
    },
    computed: {
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.documentList.length,
            }
        },
    },
    created() {
        try {     
            this.isSpinner = true;
            var self = this;
            db.collection(dbCollections.INFORMATIONDOCUMENT).where("isDeleted",'==',false)
                .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                if (snapshot.empty) {
                    this.isSpinner = false;
                    // console.error(snapshot);
                    self.documentList = [];
                    return;
                }
                snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                    if(change.type === "added") {
                        let index = self.documentList.findIndex((ele)=>{
                            return ele.id == change.doc.data().id
                        })
                        if(index > -1) {
                            let obj = {...change.doc.data()};
                            self.documentList[index] = obj
                        } else {
                            if(!change.doc.metadata.hasPendingWrites) {
                                let obj = {...change.doc.data()};
                                self.documentList.push(obj);
                            }
                        }
                    }
                    if(change.type === "modified") {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList[index] = {...change.doc.data()};
                        } 
                        else {
                            let obj = {...change.doc.data()};
                            self.documentList.push({...obj});
                        }
                    }
                    if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList.splice(index, 1);
                        }
                    }
                })
                self.$nextTick(()=>{
                    self.$forceUpdate();
                    self.documentList = self.documentList.filter((ele)=>{
                        return ele.isdocenabled == true
                    })
                    self.isSpinner = false
                })
            })
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
        }
    },
}
</script>